import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { DESIGN_SYSTEM_VIEW_CONFIGS, createDesignSystemDsViewConfig } from '../token';

export function provideDesignSystemViewsConfigs(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: DESIGN_SYSTEM_VIEW_CONFIGS,
      useValue: createDesignSystemDsViewConfig('promotions-list', () =>
        // eslint-disable-next-line @typescript-eslint/typedef
        import('../view-config/promotions-list/lvbet-web.view-config').then((mod) => mod.lvbetWebDsViewConfig)
      ),
      multi: true
    }
  ]);
}
