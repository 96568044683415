import { InjectionToken } from '@angular/core';
import { DesignSystemViewConfig, DesignSystemViewConfigKey } from './types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DESIGN_SYSTEM_VIEW_CONFIGS: InjectionToken<DesignSystemViewConfig<unknown>> = new InjectionToken<
  DesignSystemViewConfig<unknown>
>('dsViewConfigLoadData');

export function createDesignSystemDsViewConfig<V>(
  key: DesignSystemViewConfigKey,
  loadConfig: () => Promise<V>
): DesignSystemViewConfig<V> {
  return {
    key: key,
    loadConfig: loadConfig
  };
}
